//All App Notification Messages
// Delete message
export const DELETE_MESSAGE = {
  title: "Successfully Deleted",
  description: "Record has been deleted successfully.",
  style: "success",
};
// Create Message
export const CREATE_MESSAGE = {
  title: "Successfully Saved",
  description: "Your changes have been saved successfully.",
  style: "success",
};
// Update Message
export const UPDATE_MESSAGE = {
  title: "Successfully Saved",
  description: "Your changes have been saved successfully.",
  style: "success",
};
// Error Message
export const ERROR_MESSAGE = {
  title: "Something went wrong",
  description: "An error has been occurred.",
  style: "error",
};
// Approve User message
export const APPROVE_USER_MESSAGE = {
  title: "Successfully Approved",
  description: "User has been added in the organization successfully.",
  style: "success",
};
// Application Key message
export const APP_KEY_SUCEESS_MESSAGE = {
  title: "App Key Successfully Generated",
  description: "Copy the one-time generated App Key and keep it in a secure location.",
  style: "success",
};
